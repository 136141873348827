/* line 11, scss/80-themes/Saunders/70-modules/localguidedetails/module.scss */
.localguide__title,
.localguide-excerpt__link {
	text-align: center;
}

/* line 16, scss/80-themes/Saunders/70-modules/localguidedetails/module.scss */
.localguide__title {
	margin-bottom: 20px;
	color: "inherit";
}

/* line 21, scss/80-themes/Saunders/70-modules/localguidedetails/module.scss */
.localguide-excerpt__link {
	font-size: 1.2em;
	font-family: var(--font-family-headings);
	text-transform: uppercase;
	margin-top: 20px;
	padding: 5px 0;
	background-color: rgb(var(--color-global-widget));
	display: block;
	font-display: swap;
}

/* line 32, scss/80-themes/Saunders/70-modules/localguidedetails/module.scss */
.localguide-excerpt__link-icon,
.localguide-excerpt__link {
	color: rgb(var(--color-global-secondary));
}

/* line 37, scss/80-themes/Saunders/70-modules/localguidedetails/module.scss */
.localguide-excerpt__link-icon {
	padding-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .localguide-excerpt__link-icon {
	padding-right: initial;
	padding-left: 5px;
}

/*# sourceMappingURL=../../../../../true */